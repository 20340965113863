<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>FAQs</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-content>
        <ol>
          <li>
            <accordion>
              <template v-slot:title>
                <ion-label color="primary">{{ $t('faq0000001')}}</ion-label>
              </template>
              <template v-slot:content>
                <p>{{ $t('faq0000001-a')}}</p>
              </template>
            </accordion>
          </li>
          <hr>
          <li>
            <accordion>
              <template v-slot:title>
                <ion-label color="primary">{{ $t('faq0000002')}}</ion-label>
              </template>
              <template v-slot:content>
                <p>{{ $t('faq0000002-a')}}</p>
              </template>
            </accordion>
          </li>
          <hr>
          <li>            
            <accordion>
              <template v-slot:title>
                <ion-label color="primary">{{ $t('faq0000003')}}</ion-label>
              </template>
              <template v-slot:content>
                <p>{{ $t('faq0000003-a')}}</p>
              </template>
            </accordion>
          </li>
          <hr>
          <li>
            <accordion>
              <template v-slot:title>
                <ion-label color="primary">{{ $t('faq0000004')}}</ion-label>
              </template>
              <template v-slot:content>
                <p>{{ $t('faq0000004-a')}}</p>
              </template>
            </accordion>
          </li>
          <hr>
        </ol>    
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonCardContent } from '@ionic/vue';
import { defineComponent, watch } from 'vue';
import { personOutline, calendarClearSharp, newspaperOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getNewsQueryFile from '../graphql/getNews.query.gql'
import * as dayjs from 'dayjs';
import Accordion from "./template/Accordion";

export default defineComponent({
  name: 'News',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCardContent,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, Accordion
  },
  setup() {
    return {
      
    }
  },
  methods: {
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>