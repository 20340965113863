import i18next from 'i18next';
import Storage from "./storage";

const en = require('../i18nData/en');
const de = require('../i18nData/de');
const it = require('../i18nData/it');
const fr = require('../i18nData/fr');
const cz = require('../i18nData/cz');
const nl = require('../i18nData/nl');
const es = require('../i18nData/es');
const cn = require('../i18nData/cn');

import translate from './translate'

export default {
    install: (app) => {
      app.config.globalProperties.$t = key => {
        return translate(key)
      },
      app.config.globalProperties.$changeLanguage = key => {
        return i18next.changeLanguage(key)
      },
      app.config.globalProperties.$getLanguage = key => {
        return i18next.language
      }
    }
  }