<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('helpTournamentEL') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="livescoring">
          {{ livescoring.name }}
        </ion-card-title>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="livescoring">
          {{ $t('round') }} {{ livescoring.round_index }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list v-if="resultList">
          <ion-list-header :style="isPlatform('mobile') ? 'padding: 0px; margin: 0px; margin-top: 5px; font-size: 18px !important;': 'padding: 0px; margin: 0px; margin-top: 5px; font-size: 20px !important;'">
            <ion-item lines="none">{{ $t('ranking') }} </ion-item>
          </ion-list-header>

          <ion-list  lines="none" style="padding: 0px; margin: 0px; border: none;" v-for="(item, index) in resultList" :key="index" :value="item">
            <ion-item>
              <span>{{ index + 1 }} {{ item.familyname }} {{ item.name }}</span>
              <span slot="end">{{ item.overPar > 0 ? '+' + item.overPar : item.overPar }} ({{ item.score }})</span>
            </ion-item>
            <ion-item>
            </ion-item>
          </ion-list>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonListHeader,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, manSharp, womanSharp, personOutline, chevronUpOutline, chevronDownOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getLivescoringResultListQueryFile from '../../graphql/getLivescoringResultList.query.gql'
import * as dayjs from 'dayjs'; 

export default defineComponent({
  name: 'ShowResultListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let livescoring = ref(null)

    const { result: getResultListByRoundIdResult } = useQuery(getLivescoringResultListQueryFile, () => ({ id: (livescoring.value ? parseInt(livescoring.value.round_id) : 0) }), { fetchPolicy: 'no-cache' })
    let resultList = useResult(getResultListByRoundIdResult, null, data => data.getLivescoringResultList.resultList)
    return { dayjs, closeOutline, livescoring, resultList, manSharp, womanSharp, personOutline,
             isPlatform, chevronUpOutline, chevronDownOutline
    }
  },
  mounted() {
    this.livescoring = this.propsData.livescoring
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-list {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-list:first-of-type {
  border-top: 0.55px solid #c8c7cc;
}
section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>