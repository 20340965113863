<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('imprint') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card><ion-card-content style="white-space: pre-wrap;">{{ imprint }}  </ion-card-content>
    </ion-card>
    <ion-item>
      <ion-label style="margin-left: 5px;">{{ $t("Agb") }}</ion-label>
      <ion-button @click="openAgb()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-label style="margin-left: 5px;">{{ $t("DSGVO") }}</ion-label>
      <ion-button @click="openData()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, openOutline } from 'ionicons/icons';
import getImprintQueryFile from '../../graphql/getImprint.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import agbDialog from './AGBDialog'
import dataDialog from './DataLegalDialog'

export default defineComponent({
  name: 'ImprintDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,IonCardContent
              },
  setup() {
    const { result: getImprint } = useQuery(getImprintQueryFile, null, { fetchPolicy: 'no-cache' })
    const imprint = useResult(getImprint, null, data => data.getImprint.imprint)

    return { 
      closeOutline, imprint, openOutline
    }
  },
  methods: {
    async openAgb() {
      const modal = await modalController
        .create({
          component: agbDialog,
        })
      return modal.present();
    },
    async openData() {
      const modal = await modalController
        .create({
          component: dataDialog,
        })
      return modal.present();
    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>