<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('unregisterTournament') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title v-if="tournament">
          {{ tournament.name }}
        </ion-card-title>
        <ion-card-title v-if="tournament">
          {{ dayjs(tournament.begin).format('DD.MM.YYYY HH:mm') }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-item v-if="UserData">
          <ion-label class="ion-text-wrap" style="margin-left: 5px">
            <h2>{{ UserData.lastName }} {{ UserData.firstName }} ({{ UserData.hcp }})</h2>
          </ion-label>
          <ion-avatar slot="end" v-if="UserData.imageUrl">
            <img :src="UserData.imageUrl">
          </ion-avatar>
        </ion-item>
        <ion-item-divider color="" v-if="addPlayers && addPlayers.length > 0">
          <ion-label>
            {{ $t('addAdditionalPlayers') }}
          </ion-label>
        </ion-item-divider>
        <ion-item v-for="(player, i) in addPlayers" :key="i">
          <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
          <ion-label class="ion-text-wrap" style="margin-left: 5px">
            <h2>{{ player.nameLong }}</h2>
          </ion-label>
          <ion-avatar slot="end" v-if="player.showAvatar && player.avatarLink != ''">
            <img :src="player.avatarLink">
          </ion-avatar>
          <ion-icon slot="end" @click="deletePlayer(player)" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
        </ion-item>
        <ion-button :disabled="sendDisabled" style="margin-top: 15px;" expand="block" color="primary" @click="doBooking()">{{ $t('makeTournamentUnRegistration') }}</ion-button>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,IonCardContent, IonTextarea, IonSearchbar,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonSelect, IonSelectOption,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle,
IonListHeader, IonAvatar, isPlatform } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline,
         addCircleOutline, trashOutline } from 'ionicons/icons';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import * as dayjs from 'dayjs';
import unregisterTournamentMutationFile from '../../graphql/unregisterTournament.mutation.gql'
import findBookedPlayersQueryFile from '../../graphql/findBookedPlayers.query.gql'

export default defineComponent({
  name: 'RegisterTournamentDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonSelect, IonSelectOption, IonTextarea, IonSearchbar,
                IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let tournament = ref()
    let sendDisabled = ref(false)

    const { refetch: findBookedPlayersRefetch, result: findBookedPlayersResult } = useQuery(findBookedPlayersQueryFile, () => ({ tId: (tournament && tournament.value ? parseInt(tournament.value.id) : 0)  }))
    const addPlayers = useResult(findBookedPlayersResult, null, data => data.findBookedPlayers.players)

    const { mutate: unregisterTournamentMutation } = useMutation(unregisterTournamentMutationFile, { fetchPolicy: 'no-cache' });

    return {
      unregisterTournamentMutation, findBookedPlayersRefetch, addPlayers, sendDisabled,
      dayjs, tournament, addCircleOutline, trashOutline, isPlatform,
      closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline
    }
  },
  computed: {
      UserData : function(){ return this.$store.getters.UserData},
  },
  mounted() {
    this.tournament = this.propsData.tournament
  },
  methods: {
    deletePlayer(player) {
      this.addPlayers.splice(this.addPlayers.findIndex(p => p.id == player.id), 1)
    },
    doBooking() {
      this.sendDisabled = true
      this.unregisterTournamentMutation({ id: parseInt(this.tournament.id), addPlayers: JSON.stringify(this.addPlayers), language: this.$store.getters.language })
      .then(res => {
        modalController.dismiss(true)
        toastController
          .create({
            message: this.$t('unregisterSuccessful'),
            duration: 2000,
            color: 'success'
          }).then(res => res.present())
          return
          //this.findTournamentsByMonthRefetch()
      })
      .catch(err => {
        console.log(err)
        toastController
          .create({
            message: this.$t('unregisterNotSuccessful'),
            duration: 2000,
            color: 'danger',
          }).then(res => res.present())
      })

    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>