<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('registerTournament') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title v-if="tournament">
          {{ tournament.name }}
        </ion-card-title>
        <ion-card-title v-if="tournament">
          {{ dayjs(tournament.begin).format('DD.MM.YYYY HH:mm') }}
        </ion-card-title>
      </ion-card-header>
      <ion-list  v-show="tournament" lines="full" class="ion-no-margin">
        <ion-item v-if="UserData">
          <ion-label class="ion-text-wrap" style="margin-left: 5px">
            <h2>{{ UserData.lastName }} {{ UserData.firstName }} ({{ UserData.hcp }})</h2>
          </ion-label>
          <ion-avatar slot="end" v-if="UserData.imageUrl">
            <img :src="UserData.imageUrl">
          </ion-avatar>
        </ion-item>
        <ion-item v-if="tournament && tournament.teiln_web_start_wunsch == 1">
          <ion-icon slot="start" color="success" :md="timeOutline" :ios="timeOutline" style="zoom: 1.0"></ion-icon>
          <ion-select interface="popover" slot="end" v-model="selectTeetimeWish">
            <ion-select-option :value="$t('none')">{{ $t('none')}}</ion-select-option>
            <ion-select-option :value="$t('early')">{{ $t('early')}}</ion-select-option>
            <ion-select-option :value="$t('late')">{{ $t('late')}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-icon slot="start" color="primary" :md="documentTextOutline" :ios="documentTextOutline" style="zoom: 1.0"></ion-icon>
          <ion-input :placeholder="$t('comment')" v-model="selectedComment"></ion-input>
        </ion-item>
        <ion-item v-for="(player, i) in addPlayers" :key="i">
          <ion-icon color="success" :md="addCircleOutline" :ios="addCircleOutline"></ion-icon>
          <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
          <ion-label class="ion-text-wrap" style="margin-left: 5px">
            <h2>{{ player.nameLong }}</h2>
          </ion-label>
          <ion-avatar slot="end" v-if="player.showAvatar && player.avatarLink != ''">
            <img :src="player.avatarLink">
          </ion-avatar>
          <ion-icon slot="end" @click="deletePlayer(player)" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
        </ion-item>
        <ion-item-divider color="" v-if="tournament && tournament.rounds[0].team_size > (addPlayers.length + 1) && tournament.rounds[0].team_size > 1">
          <ion-label>
            {{ $t('addAdditionalPlayers') }}
          </ion-label>
        </ion-item-divider>
        <ion-item lines="full" v-if="tournament && tournament.rounds[0].team_size > (addPlayers.length + 1) && tournament.rounds[0].team_size > 1">
          <ion-icon slot="start" color="primary" :md="personAddOutline" :ios="personAddOutline" style="zoom: 1.0"></ion-icon>
          <ion-searchbar :placeholder="$t('searchPlayer')" v-model="searchText" @ionClear="findPlayer('')" @input="findPlayer($event.target.value)"></ion-searchbar>
        </ion-item>
        <ion-item v-if="playersFound && playersFound.length > 0">
          <ion-list>
            <ion-list-header>
              {{ $t('searchresult') }}
            </ion-list-header>
            <ion-item class="ion-text-wrap" v-for="(player, i) in playersFound" :key="i" @click="addPlayer(player)">
              <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
              <ion-label class="ion-text-wrap" style="margin-left: 5px">
                <h2>{{ player.nameLong }}</h2>
              </ion-label>
              <ion-avatar slot="end" v-if="player.showAvatar && player.avatarLink != ''">
                <img :src="player.avatarLink">
              </ion-avatar>
            </ion-item>
          </ion-list>
        </ion-item>
        <ion-item v-if="tournament && tournament.payOnline && tournament.priceFloat > 0">
          <h3>{{ $t('amountToPay') }}: {{ tournament.price }}</h3>
          <ion-checkbox v-if="tournament" :disabled="tournament.forceTournamentPayment && tournament.payTournamentfee" v-model="tournament.payTournamentfee" slot="end"></ion-checkbox>
        </ion-item>
        <ion-item v-show="tournament && tournament.payOnline && tournament.payTournamentfee && tournament.priceFloat > 0">
          <ion-label position="stacked">{{ $t('makeTournamentRegistrationAndPay')}}</ion-label>
          <div style="margin-top: 5px;" id="paypalButton"></div>
          <!-- <ion-select style="min-width: 100% !important;" interface="popover" v-model="paymenttype" :value="paymenttype">
            <ion-select-option v-if="isSuccessfullBraintreePaypalAuthorized" value="PayPalAccount">{{ $t('paypalPayment') }}</ion-select-option>  
            <ion-select-option v-if="isSuccessfullBraintreeCreditCardAuthorized" value="CreditCard">{{ $t('creditCardPayment') }}</ion-select-option>  
          </ion-select>-->
        </ion-item>
        <ion-button v-if="tournament && (!tournament.payOnline || !tournament.payTournamentfee)" :disabled="sendDisabled" style="margin-top: 15px;" expand="block" color="primary" @click="doBooking()">{{ tournament.payOnline && tournament.payTournamentfee ? $t('makeTournamentRegistrationAndPay') : $t('makeTournamentRegistration') }}</ion-button>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { loadingController, IonButton, IonContent, IonHeader, IonTitle, toastController,IonCardContent, IonTextarea, IonSearchbar,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonSelect, IonSelectOption,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle,
IonListHeader, IonAvatar, isPlatform } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline,
         addCircleOutline, trashOutline } from 'ionicons/icons';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import * as dayjs from 'dayjs';
import registerTournamentMutationFile from '../../graphql/registerTournament.mutation.gql'
import findPlayersQueryFile from '../../graphql/findPlayers.query.gql'
import createPaymentMutationFile from '../../graphql/createPayment.mutation.gql'
import translate from '@/plugins/translate'
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'RegisterTournamentDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonSelect, IonSelectOption, IonTextarea, IonSearchbar,
                IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let tournament = ref()
    let selectTeetimeWish = ref(translate('none'))
    let selectedComment = ref('')
    let searchText = ref('')
    let addPlayers = ref([])
    let sendDisabled = ref(false)
    const clubInfo = ref(getClubInfo())

    const { refetch: findPlayersRefetch, result: findPlayersResult } = useQuery(findPlayersQueryFile, () => ({ searchTerm: '' }))
    const playersFound = useResult(findPlayersResult, null, data => data.findPlayers.players)

    const { mutate: registerTournamentMutation } = useMutation(registerTournamentMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutation } = useMutation(createPaymentMutationFile, { fetchPolicy: 'no-cache' });

    return {
      registerTournamentMutation, findPlayersRefetch, playersFound, addPlayers, searchText, sendDisabled,
      dayjs, tournament, selectTeetimeWish, selectedComment, addCircleOutline, trashOutline, isPlatform,
      closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline, createPaymentMutation, clubInfo
    }
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      UserData : function(){ return this.$store.getters.UserData},
      paymenttype : function(){ 
        return 'PayPalAccount'
/*        if (this.isSuccessfullBraintreePaypalAuthorized && this.isSuccessfullBraintreeCreditCardAuthorized )
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreePaypalAuthorized)
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreeCreditCardAuthorized)
          return 'CreditCard' */
       },
  },
  mounted() {
    this.tournament = this.propsData.tournament

    loadScript({ "client-id": "AQe-1_UiC8WAkzp_FORkgq6UT6rRyNqXv2g74Q7ZczFrEIGCwAG65v6m6W3BcNNamN7gtuoNfahiRH0v", currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          const p = await this.createPaymentMutation({ description: this.tournament.name, amount: parseFloat(this.tournament.priceFloat), paymentType: 'payPal' })
          if (!p.data.createPayment.success) {
            return ''
          }
          return p.data.createPayment.token
         },
         onError: async (err) => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
              this.buttonDisabled = true
              loadingController.create().then(res => {
                res.present()
              }).catch(err => { })
              let startWishDB = ''
              if (this.$t('early') == this.selectTeetimeWish)
                startWishDB = 'F'
              else if (this.$t('late') == this.selectTeetimeWish)
                startWishDB = 'S'
              this.registerTournamentMutation({ id: parseInt(this.tournament.id), startWishDB: startWishDB, 
                startWish: this.selectTeetimeWish, comment: this.selectedComment, 
                addPlayers: JSON.stringify(this.addPlayers), isMobile: isPlatform('mobile'), price: this.tournament.priceFloat, paymentType: 'PayPalAccount', language: this.$store.getters.language, orderId: data.orderID })
            .then(res => {
                toastController
                  .create({
                    message: this.$t('registerSuccessful'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
                /*toastController
                  .create({
                    message: this.$t('registerNotSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())*/
            }) 
         },
         onCancel: async () => {
          await this.createPaymentMutation({ amount: parseFloat(this.tournament.priceFloat), paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});
  },
  methods: {
    deletePlayer(player) {
      this.addPlayers.splice(this.addPlayers.findIndex(p => p.id == player.id), 1)
    },
    addPlayer(player) {
      if (this.addPlayers.findIndex(p => p.id == player.id) >= 0)
        return
      this.searchText = ''
      this.findPlayersRefetch({ searchTerm: ''})
      this.addPlayers.push(player)
    },
    async findPlayer(searchTerm) {
      if (searchTerm.length > 3) {
        this.findPlayersRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findPlayersRefetch({ searchTerm: ''})
      }
    },
    doBooking() {
      this.sendDisabled = true
      let startWishDB = ''
      if (this.$t('early') == this.selectTeetimeWish)
        startWishDB = 'F'
      else if (this.$t('late') == this.selectTeetimeWish)
        startWishDB = 'S'
      this.registerTournamentMutation({ id: parseInt(this.tournament.id), startWishDB: startWishDB, 
        startWish: this.selectTeetimeWish, comment: this.selectedComment, 
        addPlayers: JSON.stringify(this.addPlayers), isMobile: isPlatform('mobile'), price: this.tournament.payTournamentfee ? this.tournament.priceFloat : 0, paymentType: this.paymenttype, language: this.$store.getters.language })
      .then(res => {
        modalController.dismiss(true)
        toastController
          .create({
            message: this.$t('registerSuccessful'),
            duration: 2000,
            color: 'success'
          }).then(res => res.present())
          return
          //this.findTournamentsByMonthRefetch()
      })
      .catch(err => {
        /*console.log(err)
        toastController
          .create({
            message: this.$t('registerNotSuccessful'),
            duration: 2000,
            color: 'danger',
          }).then(res => res.present())*/
      })

    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>