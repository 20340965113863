<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('proHelp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item-divider>
        <ion-label>
        {{ $t('proConfigs') }}
        </ion-label>
      </ion-item-divider>
      <ion-item>
          <ion-label class="ion-text-wrap" style="font-size: 12px" slot="start">&nbsp;{{ $t('maxDays') }}</ion-label>
          <ion-label v-if="selectedPro" slot="end">&nbsp;{{ selectedPro.maxDays == 0 ? $t('unlimited') : selectedPro.maxDays }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label class="ion-text-wrap" style="font-size: 12px" slot="start">&nbsp;{{ $t('minPersonsBookable') }}</ion-label>
          <ion-label v-if="selectedPro" slot="end">&nbsp;{{ selectedPro.minSlots }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label class="ion-text-wrap" style="font-size: 12px" slot="start">&nbsp;{{ $t('maxPersonsBookable') }}</ion-label>
          <ion-label v-if="selectedPro" slot="end">&nbsp;{{ selectedPro.maxSlots }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label class="ion-text-wrap" style="font-size: 12px" slot="start">&nbsp;{{ $t('precedingHours') }}</ion-label>
          <ion-label v-if="selectedPro" slot="end">&nbsp;{{ selectedPro.precedingHours }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label class="ion-text-wrap" style="font-size: 12px" slot="start">&nbsp;{{ $t('precedingStornoMinutes') }}</ion-label>
          <ion-label v-if="selectedPro" slot="end">&nbsp;{{ selectedPro.precedingStornoMinutes }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, 
          personAddOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'HelpProDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider },
  props: ['propsData'],
  setup() {
    let selectedPro = ref()
    return {
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline, selectedPro
    }
  },
  mounted() {
    this.selectedPro = this.propsData.selectedPro
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>