<template>
  <div>
    <div @click="toggleAccordion()">
      <slot name="title" />
    </div>

    <div v-show="isOpen">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>