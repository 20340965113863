import { ref } from 'vue'

let clubInfo = ref();
let appPages = ref();

export const setClubInfo = function(value) {
  clubInfo.value = value
}
export const getClubInfo = function() {

  return {
    clubInfo,
  }
}

export const setAppPages = function(value) {
  appPages.value = value
}
export const getAppPages = function() {

  return {
    appPages,
  }
}