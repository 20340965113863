import jwt_decode from "jwt-decode";
import Storage from "@/plugins/storage.js";

const state = {
    isSuccessfullBraintreePaypalAuthorized: false,
    isSuccessfullBraintreeCreditCardAuthorized: false,
    maskedNumber: '',
    expirationDate: '',
};

const getters = {
    isSuccessfullBraintreePaypalAuthorized: state => state.isSuccessfullBraintreePaypalAuthorized,
    isSuccessfullBraintreeCreditCardAuthorized: state => state.isSuccessfullBraintreeCreditCardAuthorized,
    maskedNumber: state => state.maskedNumber,
    expirationDate: state => state.expirationDate,
}

const mutations = {
    SET_SUCCESSFULL_BRAINTREE_PAYPAL_AUTHORIZED(state, value){
      state.isSuccessfullBraintreePaypalAuthorized = value
    },
    SET_SUCCESSFULL_BRAINTREE_CREDITCARD_AUTHORIZED(state, value){
      state.isSuccessfullBraintreeCreditCardAuthorized = value
    },
    SET_MASKEDNUMBER(state, value){
      state.maskedNumber = value
    },
    SET_EXPIRATIONDATE(state, value){
      state.expirationDate = value
    },
};

const actions = {
    setSuccessfullBraintreePaypalAuthorized({commit}, value){
      return new Promise((resolve, reject) => {
        commit('SET_SUCCESSFULL_BRAINTREE_PAYPAL_AUTHORIZED', value)
        resolve()
      })
    },
    setSuccessfullBraintreeCreditCardAuthorized({commit}, value){
      return new Promise((resolve, reject) => {
        commit('SET_SUCCESSFULL_BRAINTREE_CREDITCARD_AUTHORIZED', value)
        resolve()
      })
    },
    setMaskedNumber({commit}, value){
      return new Promise((resolve, reject) => {
        commit('SET_MASKEDNUMBER', value)
        resolve()
      })
    },
    setExpirationDate({commit}, value){
      return new Promise((resolve, reject) => {
        commit('SET_EXPIRATIONDATE', value)
        resolve()
      })
    },
};


export default {
    state,
    mutations,
    actions,
    getters
}