<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('bookingCourseHelp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-label class="ion-text-wrap">
      {{ $t('bookingCourseHelp1') }}
    </ion-label>
      <ion-item-divider>
        <ion-label>
        {{ $t('courseConfigs') }}
        </ion-label>
      </ion-item-divider>
      <ion-item>
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('maxDays') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.maxDays == 0 ? $t('unlimited') : findCourseConfigsResult.findCourseConfigs.maxDays }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('timeSwitch') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.timeSwitch }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('maxBookingsInFuture') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.maxBookings == 0 ? $t('unlimited') : findCourseConfigsResult.findCourseConfigs.maxBookings }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('maxBookingsPerDay') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.maxBookingsPerDay == 0 ? $t('unlimited') : findCourseConfigsResult.findCourseConfigs.maxBookingsPerDay }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('maxBookingsPerWeek') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.maxBookingsPerWeek == 0 ? $t('unlimited') : findCourseConfigsResult.findCourseConfigs.maxBookingsPerWeek }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('allowAlwaysBookingHours') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.allowAlwaysBookingHours }}</ion-label>
      </ion-item>
      <ion-item-divider v-if="findCourseConfigsResult && findCourseConfigsResult.findCourseConfigs && findCourseConfigsResult.findCourseConfigs.showMyBookings">
        <ion-label>
        {{ $t('myCourseData') }}
        </ion-label>
      </ion-item-divider>
      <ion-item v-if="findCourseConfigsResult && findCourseConfigsResult.findCourseConfigs && findCourseConfigsResult.findCourseConfigs.showMyBookings">
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('myFutureBookings') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.myBookings }}</ion-label>
      </ion-item>
      <ion-item v-if="findCourseConfigsResult && findCourseConfigsResult.findCourseConfigs && findCourseConfigsResult.findCourseConfigs.showMyBookings">
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('myBookingToday') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.myBookingsToday }}</ion-label>
      </ion-item>
      <ion-item v-if="findCourseConfigsResult && findCourseConfigsResult.findCourseConfigs && findCourseConfigsResult.findCourseConfigs.showMyBookings">
          <ion-label style="font-size: 12px" slot="start">&nbsp;{{ $t('myBookingWeek') }}</ion-label>
          <ion-label v-if="findCourseConfigsResult" slot="end">&nbsp;{{ findCourseConfigsResult.findCourseConfigs.myBookingsWeek }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, 
          personAddOutline } from 'ionicons/icons';
import { useQuery } from '@vue/apollo-composable'
import findCourseConfigsQueryFile from '../graphql/findCourseConfigs.query.gql'
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'HelpCourseDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider },
  props: ['propsData'],
  setup(props) {
    const { result: findCourseConfigsResult } = useQuery(findCourseConfigsQueryFile, () => ({ id: props.propsData.selectedTeetimeId, date: props.propsData.selectedDate }), { fetchPolicy: 'no-cache' })
    return {
      findCourseConfigsResult,
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>